import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Controller, Scene } from 'react-scrollmagic'

import {
  Headline,
  Subtitle,
  ParagraphM,
  ParagraphS,
} from '../styles/Typography'
import { ButtonPrimary } from '../styles/ButtonLayout'
import { Container } from '../styles/PageLayout'
import { FooterLayout } from '../styles/FooterLayout'

import ArrowRight from './icons/ArrowRight'

/**
 * The main footer of the website including
 * the contact link and newsletter form.
 * @returns {FunctionComponent}
 */
const Footer = () => {
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const isInvalid = email === ''

  const handleSubmit = async (e) => {
    e.preventDefault()

    console.log(email)
    setIsSubmitted(true)
  }

  return (
    <FooterLayout>
      <div className="link-wrapper">
        <div className="link-wrapper-inner" id="link-wrapper-inner">
          <Controller>
            <Scene
              classToggle="reveal"
              triggerElement="#link-wrapper-inner"
              reverse={false}
            >
              <Headline>
                <Link to="/contact">
                  Colorize <span>your</span> photos
                </Link>
              </Headline>
            </Scene>
          </Controller>
        </div>
      </div>
      <div className="connect-wrapper" id="connect-wrapper">
        <Container
          mobileColumn
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <div className="colaborate">
            <Subtitle weight={700}>Let's collaborate</Subtitle>
            <Controller>
              <Scene
                classToggle="reveal"
                triggerElement="#connect-wrapper"
                reverse={false}
              >
                <ParagraphM>
                  Now you have the opportunity to see your valuable photographs
                  in color for the first time. Get in touch with us by sending
                  an email or message through our social media accounts.
                </ParagraphM>
              </Scene>
            </Controller>
            <Controller>
              <Scene
                classToggle="reveal"
                triggerElement="#connect-wrapper"
                reverse={false}
              >
                <div className="btn">
                  <Link to="/contact">
                    <ButtonPrimary>Contact Us</ButtonPrimary>
                  </Link>
                </div>
              </Scene>
            </Controller>
          </div>
          <div className="social">
            <Controller>
              <Scene
                classToggle="reveal"
                triggerElement="#connect-wrapper"
                reverse={false}
              >
                <div className="social-links-group">
                  <ParagraphS className="social-title">Follow us</ParagraphS>
                  <div className="social-links">
                    <ParagraphM>
                      <a
                        href="https://www.instagram.com/colorizedmk"
                        target="_blank"
                      >
                        Instagram
                      </a>
                    </ParagraphM>
                    <ParagraphM>
                      <a
                        href="https://www.facebook.com/colorizedmk"
                        target="_blank"
                      >
                        Facebook
                      </a>
                    </ParagraphM>
                    <ParagraphM>
                      <a
                        href="https://www.pinterest.com/colorizedmk"
                        target="_blank"
                      >
                        Pinterest
                      </a>
                    </ParagraphM>
                  </div>
                </div>
              </Scene>
            </Controller>
            <Controller>
              <Scene
                classToggle="reveal"
                triggerElement="#connect-wrapper"
                reverse={false}
              >
                <div className="social-newsletter-group">
                  <ParagraphS className="social-title">
                    Join our mailing list
                  </ParagraphS>
                  <div className="social-newsletter">
                    <ParagraphM>
                      For more interesting feed in your inbox.
                    </ParagraphM>
                    <ParagraphM>We promise we won't spam.</ParagraphM>
                    {isSubmitted ? (
                      <div className="success">
                        <ParagraphM>Thank you!</ParagraphM>
                      </div>
                    ) : (
                      <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="form-control">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={({ target }) => setEmail(target.value)}
                          />
                          <button type="submit" disabled={isInvalid}>
                            <ArrowRight color="#fff" />
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </Scene>
            </Controller>
          </div>
        </Container>
        <Controller>
          <Scene
            classToggle="reveal"
            triggerElement="#connect-wrapper"
            reverse={false}
          >
            <div className="copyright-wrapper">
              <ParagraphS>
                © 2023 - Colorized.mk, all rights reserved
              </ParagraphS>
            </div>
          </Scene>
        </Controller>
      </div>
    </FooterLayout>
  )
}

export default Footer
